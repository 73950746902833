<template>
    <div>
        <v-container class="navbar" v-if="!isMobile">
            <v-row>
                <v-col cols="2" class="navbar-logo">
                    <img :src="storeData.logo" alt=""
                        @click="$router.push($route.params.storeSlug == undefined ? `/` : `/${$route.params.storeSlug}`)">
                </v-col>
                <v-col cols="8" class="navbar-search">
                    <v-row>
                        <v-col cols="10">
                            <input type="text" class="input-default" @keyup.enter="search" v-model="term"
                                placeholder="Pesquisar por produtos...">
                        </v-col>
                        <v-col cols="2">
                            <v-btn @click="search" icon>
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <small style="color: red"> {{ search_return }}</small>
                </v-col>
                <v-col cols="2" class="navbar-icons">
                    <v-badge :content="productsInCart" :value="productsInCart" color="black" overlap>
                        <img src="../assets/icons/cart.svg" alt=""
                            @click="$router.push($route.params.storeSlug == undefined ? `/cart` : `/${$route.params.storeSlug}/cart`)">
                    </v-badge>
                </v-col>
            </v-row>
            <v-row class="categories">
                <v-col md="4" offset-md="4">
                    <ul>
                        <li>
                            <v-menu open-on-hover bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text v-bind="attrs" v-on="on"
                                        @click="$router.push($route.params.storeSlug == undefined ? `/` : `/${$route.params.storeSlug}`)">
                                        INÍCIO
                                    </v-btn>
                                </template>
                            </v-menu>
                        </li>
                        <li>
                            <v-menu open-on-hover bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text v-bind="attrs" v-on="on" @click="$router.push($route.params.storeSlug == undefined ? `/category/todos-oculos-de-sol` : `/${$route.params.storeSlug}/category/todos-oculos-de-sol`)">
                                        PRODUTOS
                                    </v-btn>
                                </template>
                            </v-menu>
                        </li>
                        <li>
                            <v-menu open-on-hover bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text v-bind="attrs" v-on="on" @click="$router.push($route.params.storeSlug == undefined ? `/category/acessorios` : `/${$route.params.storeSlug}/category/acessorios`)">
                                        ACESSÓRIOS
                                    </v-btn>
                                </template>
                            </v-menu>
                        </li>
                    </ul>
                </v-col>
                <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" color="error" top right>
                    {{ snackbarMessage }}
                </v-snackbar>
            </v-row>
        </v-container>

        <v-container class="navbar-mobile" v-if="isMobile">
            <v-row>
                <v-col cols="2" class="navbar-menu-icon" @click.stop="drawer = !drawer">
                    <img src="@/assets/icons/menu.svg" alt="">
                </v-col>
                <v-col cols="6" class="navbar-logo">
                    <img :src="storeData.logo" alt=""
                        @click="$router.push($route.params.storeSlug == undefined ? `/` : `/${$route.params.storeSlug}`)">
                </v-col>
                <v-col cols="4" class="navbar-icons">
                    <v-badge :content="productsInCart" :value="productsInCart" color="black" overlap>
                        <img src="../assets/icons/cart.svg" alt=""
                            @click="$router.push($route.params.storeSlug == undefined ? `/cart` : `/${$route.params.storeSlug}/cart`)">
                    </v-badge>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="navbar-search">
                    <input type="text" class="input-default" @keyup.enter="search" v-model="term"
                        placeholder="Pesquisar por produtos...">
                </v-col>
                <v-col cols="2">
                    <v-btn @click="search" icon>
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
                <small style="color: red"> {{ search_return }}</small>
            </v-row>
            <v-navigation-drawer v-model="drawer" absolute temporary>
                <v-list-item>
                    <v-img :src="storeData.logo"></v-img>
                </v-list-item>
                <v-divider></v-divider>
                <v-container style="height: 100vh; overflow: auto;padding-bottom: 300px;">
                    <v-row>
                        <v-col>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <button style="width: 100%;text-align: left !important;"
                                        @click="$router.push($route.params.storeSlug == undefined ? `/` : `/${$route.params.storeSlug}`)"
                                        v-bind="attrs" v-on="on">
                                        INICIO
                                    </button>
                                </template>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <button style="width: 100%;text-align: left !important;" @click="$router.push($route.params.storeSlug == undefined ? `/category/todos-oculos-de-sol` : `/${$route.params.storeSlug}/category/todos-oculos-de-sol`)" v-bind="attrs" v-on="on">
                                        PRODUTOS
                                    </button>
                                </template>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <button style="width: 100%;text-align: left !important;" @click="$router.push($route.params.storeSlug == undefined ? `/category/acessorios` : `/${$route.params.storeSlug}/category/acessorios`)" v-bind="attrs" v-on="on">
                                        ACESSÓRIOS
                                    </button>
                                </template>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-navigation-drawer>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    props: {
        isMobile: Boolean,
        productsInCart: Number,
        categories: Array
    },
    data() {
        return {
            storeData: {},
            drawer: null,
            term: '',
            search_return: '',
            snackbar: false,
            snackbarMessage: '',
            snackbarTimeout: 3000
        }
    },
    async mounted() {
        this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
    },
    methods: {
        showSnackbar(message) {
            this.snackbarMessage = message;
            this.snackbar = true;
        },
        async search() {
            if (this.term.length < 3) {
                this.search_return = 'Digite pelo menos 3 caracteres'
                return
            }
            this.$router.push(this.$route.params.storeSlug == undefined ? `/search/${this.term}` : `/${this.$route.params.storeSlug}/search/${this.term}`)
        }
    }
}
</script>

<style scoped>
.input-default {
    width: 100%;
    padding: 10px;
    padding-left: 15px;
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    border-radius: 50px;
}

.categories ul {
    display: flex;
    list-style: none;
}

/* NAVBAR */
.navbar {
    padding: 20px;
}

.navbar .navbar-logo img {
    width: 100%;
    cursor: pointer;
}

.navbar .navbar-icons img {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 10px;
    cursor: pointer;
}

.navbar .navbar-search {
    padding-top: 15px;
}


/* NAVBAR - MOBILE */
.navbar-mobile {
    padding: 20px;
}

.navbar-mobile .navbar-logo img {
    margin-top: 4px;
    width: 100%;
    cursor: pointer;
}

.navbar-mobile .navbar-icons img {
    margin-top: 14px;
    margin-left: 10px;
    font-size: 10px;
    cursor: pointer;
    text-align: right;
    float: right;
}

.navbar-mobile .navbar-menu-icon img {
    display: block;
    margin: 0 auto;
    margin-top: 16px;
    width: 60%;
    cursor: pointer;
}
</style>
