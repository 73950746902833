import Vue from 'vue'
import VueRouter from 'vue-router'

// LAYOUTS
import HomeLayout from '../layouts/HomeLayout.vue'

// VIEWS
import StorePage from '../views/StorePage.vue'
import DetailsPage from '../views/DetailsPage.vue'
import CartPage from '@/views/CartPage.vue'
import PaymentPage from '@/views/PaymentPage.vue'
import CategoryPage from '@/views/CategoryPage.vue'
import SearchPage from '@/views/SearchPage.vue'
import OrderPage from '@/views/OrderPage.vue'
import CategoriesPage from '@/views/CategoriesPage.vue'
Vue.use(VueRouter)

var routes = []

if(window.location.hostname === 'oculos2w.com' || window.location.hostname === 'teste.oculos2w.com' || window.location.hostname === 'www.oculos2w.com' || window.location.hostname === 'www.teste.oculos2w.com'){
  console.log('> NÃO TEM URL PRÓPRIA')
  window.localStorage.setItem('urlStore', 'oculos2w.com')
  routes = [
    { 
      path: '/', 
      component: HomeLayout, 
      children: [
        { 
          path: '/:storeSlug', 
          component: StorePage
        },
        { 
          path: '/:storeSlug/product/:id', 
          component: DetailsPage
        },
        { 
          path: '/:storeSlug/cart', 
          component: CartPage
        },
        { 
          path: '/:storeSlug/category/:categorySlug', 
          component: CategoryPage
        },
        { 
          path: '/:storeSlug/search/:search', 
          component: SearchPage
        },
        { 
          path: '/:storeSlug/payment', 
          component: PaymentPage
        },
        { 
          path: '/:storeSlug/order/:token', 
          component: OrderPage
        },
        { 
          path: '/:storeSlug/categories', 
          component: CategoriesPage
        },
        ,
        {
          path: '/:storeSlug/exchanges_and_returns',
          component: () => import('../views/ExchangesAndReturns.vue')
        },
        {
          path: '/:storeSlug/privacy_policy',
          component: () => import('../views/PrivacyPolicy.vue')
        },
        {
          path: '/:storeSlug/shipping_policy',
          component: () => import('../views/ShippingPolicy.vue')
        }
        
      ] 
    }
  ]
}else{
  console.log('> TEM URL PRÓPRIA')
  window.localStorage.setItem('urlStore', window.location.hostname)
  routes = [
    { 
      path: '/', 
      component: HomeLayout, 
      children: [
        { 
          path: '/', 
          component: StorePage
        },
        { 
          path: '/product/:id', 
          component: DetailsPage
        },
        { 
          path: '/cart', 
          component: CartPage
        },
        { 
          path: '/category/:categorySlug', 
          component: CategoryPage
        },
        { 
          path: '/search/:search', 
          component: SearchPage
        },
        { 
          path: '/payment', 
          component: PaymentPage
        },
        { 
          path: '/order/:token', 
          component: OrderPage
        },
        { 
          path: '/categories', 
          component: CategoriesPage
        },
        {
          path: '/exchanges_and_returns',
          component: () => import('../views/ExchangesAndReturns.vue')
        },
        {
          path: '/privacy_policy',
          component: () => import('../views/PrivacyPolicy.vue')
        },
        {
          path: '/shipping_policy',
          component: () => import('../views/ShippingPolicy.vue')
        }
      ] 
    }
  ]
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
