<template>
    <v-card
      flat
      tile
    >
      <v-window
        v-model="onboarding"
        reverse
      >
        <v-window-item
          v-for="n in images"
          :key="`card-${n.id}`"
        >
          <v-card>
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
                <v-col>
                    <v-img
                    lazy-src="@/assets/lazy.jpg"
                    :src="n.src"
                    contain
                    rel="preload"
                    />
                </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
  
      <v-card-actions class="justify-space-between">
        <v-btn
          text
          @click="prev"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-item-group
          v-model="onboarding"
          class="text-center"
          mandatory
        >
          <v-item
            v-for="n in parseInt(images.length)"
            :key="`btn-${n}`"
            v-slot="{ active, toggle }"
          >
            <v-btn
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
        <v-btn
          text
          @click="next"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
  export default {
    props: {
      images: Array
    },
    data: () => ({
      onboarding: 0,
    }),

    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === this.images.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.images.length - 1
          : this.onboarding - 1
      },
    },
  }
</script>