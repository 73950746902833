<template>
    <v-container fluid class="costumers">
        <v-row>
            <v-col cols="12" class="costumers-title" v-if="!isMobile">
                <h2>{{title}}</h2>
            </v-col>
            <v-col cols="12" class="costumers-mobile-title" v-if="isMobile">
                <h2>{{title}}</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-sheet
                    class="mx-auto"
                    max-width="100%"
                >
                    <v-slide-group
                    multiple
                    show-arrows
                    >
                        <v-slide-item
                            v-for="r in rates"
                            :key="r.id"
                        >
                            <v-card
                                class="mx-auto rate-card"
                                max-width="400"
                                outlined
                            >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <p class="rate">
                                        <img src="../assets/icons/star.svg" style="width: 22.58px;" v-for="r in r.rate" :key="r.id" alt="">
                                    </p>
                                    <h3>{{  r.name }}</h3>
                                    <p class="text">
                                        "{{ r.text }}"
                                    </p>
                                </v-list-item-content>
                            </v-list-item>
                            </v-card>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'CostumersRate',
    props: {
        isMobile: Boolean,
        rates: Array,
        title: String
    },
}
</script>

<style scoped>
    .costumers .costumers-title{
        text-align: center;
        text-transform: uppercase;
        font-family: Exo;
        font-size: 35px;
        font-weight: 700;
        line-height: 63.79px;
        color: #000;
        padding-top: 100px;
    }

    .costumers .costumers-mobile-title{
        text-align: center;
        text-transform: uppercase;
        font-family: Exo;
        font-size: 28px;
        font-weight: 700;
        line-height: 63.79px;
        color: #000;
        padding-top: 100px;
    }

    .costumers .rate-card{
        margin-right: 10px !important;
        margin-left: 10px !important;
        padding: 10px;
        border-radius: 20px;
    }


    .costumers .rate-card .text{
        font-family: Exo;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #00000099;


    }

    .costumers .rate-card h3{
        font-family: Exo;
        font-size: 20px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        margin: 10px 0 10px 0
    }
</style>