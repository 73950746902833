<template>
    <v-container fluid v-if="notice" class="notice">
        <v-row >
            <v-col class="notice-text">
                {{ message }}
                <div class="notice-close" @click="notice = false" v-if="!isMobile"><img src="../assets/icons/times.svg" alt=""></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Notice',
    props: {
        isMobile: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            notice: true,
        }
    },
}
</script>

<style scoped>
    .notice{
        background-color: black;
        text-align: center;
        color: #fff;
    }
    .notice .notice-text{
        padding: 10px;
    }

    .notice .notice-close{
        cursor: pointer;
        position: absolute;
        right: 10%;
        top: 10px;
    }
</style>