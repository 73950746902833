<template>
    <v-app>
        <!-- <Notice :isMobile="$store.getters.isMobile" :message="message"/> -->

        <Navbar :isMobile="$store.getters.isMobile" :categories="categories" :productsInCart="productsInCart"/>

        <v-container :style="!$store.getters.isMobile ? 'width: 60%' : 'width: 100%'" class="body">
            <v-row>
                <v-col>
                    <h3>BASTA CLICAR NA IMAGEM PARA SER DIRECIONADO Á PÁGINA DE PRODUTOS</h3>
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-col>
                    <img @click="$router.push($route.params.storeSlug == undefined ? `/category/catalogo-completo-oculos-atacado` : `/${$route.params.storeSlug}/category/catalogo-completo-oculos-atacado`)" src="../assets/banners-categorias/Catálogo Completo.jpg" alt="">
                </v-col>
            </v-row> -->
            <v-row>
                <v-col>
                    <img @click="$router.push($route.params.storeSlug == undefined ? `/category/lancamentos-junho` : `/${$route.params.storeSlug}/category/lancamentos-junho`)" src="../assets/banners-categorias/Lançamentos de Maio - Desktop.jpg" alt="">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <img @click="$router.push($route.params.storeSlug == undefined ? `/category/oculos-solar-masculino` : `/${$route.params.storeSlug}/category/oculos-solar-masculino`)" src="../assets/banners-categorias/Coleção Masculina.jpg" alt="">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <img @click="$router.push($route.params.storeSlug == undefined ? `/category/oculos-solar-femino` : `/${$route.params.storeSlug}/category/oculos-solar-femino`)" src="../assets/banners-categorias/Coleção Feminina.jpg" alt="">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <img @click="$router.push($route.params.storeSlug == undefined ? `/category/oculos-solar-luxo-atacado` : `/${$route.params.storeSlug}/category/oculos-solar-luxo-atacado`)" src="../assets/banners-categorias/Premium.jpg" alt="">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <img @click="$router.push($route.params.storeSlug == undefined ? `/category/oculos-solar-esportivo` : `/${$route.params.storeSlug}/category/oculos-solar-esportivo`)" src="../assets/banners-categorias/Bambu e esportivos.jpg" alt="">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <img @click="$router.push($route.params.storeSlug == undefined ? `/category/oculos-solar-classico` : `/${$route.params.storeSlug}/category/oculos-solar-classico`)" src="../assets/banners-categorias/Clássicos.jpg" alt="">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <img @click="$router.push($route.params.storeSlug == undefined ? `/category/armacoes-de-grau` : `/${$route.params.storeSlug}/category/armacoes-de-grau`)" src="../assets/banners-categorias/Óculos de grau-Desktop.png" alt="">
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-col>
                    <img @click="$router.push($route.params.storeSlug == undefined ? `/category/outlet` : `/${$route.params.storeSlug}/category/outlet`)" src="../assets/banners-categorias/outlet.png" alt="">
                </v-col>
            </v-row> -->
            
        </v-container>
    

        <Footer :isMobile="$store.getters.isMobile" />

        <Loading v-if="loading"/>
    </v-app>
</template>

<script>
import Notice from '@/components/Notice.vue'
import Navbar from '@/components/Navbar.vue'
import FourProducts from '@/components/FourProducts.vue'
import CostumersRate from '@/components/CostumersRate.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})
export default {
    name: 'CategoriesPage',
    metaInfo() {
        return {
            title: `${this.storeData.name}`
        }
    },
    components: {
        Notice,
        Navbar,
        FourProducts,
        CostumersRate,
        Footer,
        Loading
    },
    data(){
        return {
            loading: true,
            productsInCart: 0,
            storeData: {},
            categories: [],
            newProducts: [],
            categories: []
        }
    },
    async mounted(){
        this.verifyQntCart()
        
    },
    async created(){
        this.loading = true;
        this.verifyQntCart()
        this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
        await this.getCategories()
        this.loading = false;
    },
    methods: {
        verifyQntCart(){
            this.productsInCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0
        },
        async getCategories(){
            try {
                let response = await api.get(`categories`)
                this.categories = response.data
            } catch (error) {
                console.log(error)
            }
        },
    }
}
</script>

<style scoped>
    *{
        font-family: "Exo", sans-serif;
    }
    ::placeholder {
        color: #00000066;
        opacity: 1; /* Firefox */
    }

    .input-default{
        width: 100%;
        padding: 10px;
        padding-left: 15px;
        border: 1px solid #F0F0F0;
        background-color:#F0F0F0;
        border-radius: 50px;
    }

    img{
        width: 100%;
        cursor: pointer;
    }
    


</style>