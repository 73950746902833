import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
import config from './config'
const api = axios.create({
    baseURL: config.baseURL
})

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    storeData: {
        "id": 0,
        "name": "",
        "slug": "",
        "primaryColor": "#ffff",
        "secondaryColor": "#000000",
        "topCase": "https://firebasestorage.googleapis.com/v0/b/nagenda-adm.appspot.com/o/banner-topo.jpg?alt=media&token=eb3a787f-bd46-400a-a7a6-b1d73ae87e1c",
        "facebookLink": null,
        "instagramLink": null,
        "twitterLink": null,
        "youtubeLink": null,
        "tiktokLink": null,
        "createdAt": null,
        "updatedAt": null
    }
  },
  mutations: {
    update_dados(state, payload) {
      state.storeData = payload
    },
  },
  actions: {
    update_dados(state, payload) {
      state.storeData = payload
    },
    async getStore(state, slug){
        try {
          let response = await api.get(`/store/${slug}`)
            if(!response.data) return
            localStorage.setItem('slug', response.data.slug)
            localStorage.setItem('storeData', JSON.stringify(response.data))
            return JSON.parse(localStorage.getItem('storeData'))
            
          if(localStorage.getItem('slug')){
            if(localStorage.getItem('slug') != slug){
              localStorage.clear();
              location.reload()
            }else{
              return JSON.parse(localStorage.getItem('storeData'))
            }
          }else{
            let response = await api.get(`/store/${slug}`)
            if(!response.data) return
            localStorage.setItem('slug', response.data.slug)
            localStorage.setItem('storeData', JSON.stringify(response.data))
            return JSON.parse(localStorage.getItem('storeData'))
          }

        } catch (error) {
            console.log(error)
        }
    },
  },
  getters: {
    getStoreData: state => {
      return state.storeData
    },
    isMobile(){
      return window.innerWidth < 768
    },
    async getConfig(){
      return {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
    },
    
  }
});
