<template>
    <v-app>
        <Navbar :isMobile="$store.getters.isMobile" :categories="categories" :productsInCart="productsInCart" />

        <v-container fluid class="top">
            <v-row>
                <v-col class="top-banner" @click="goTo('/category/todos-oculos-de-sol')">
                    <v-carousel hide-delimiters height="auto">
                        <v-carousel-item v-for="(image, i) in carouselImages" :key="i">
                            <v-img :src="image" class="carousel-img"></v-img>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid class="promo-section">
            <v-row>
                <v-col v-for="(promo, index) in promos" :key="index" cols="12" md="4">
                    <div class="promo-card">
                        <div class="promo-image-wrapper">
                            <img :src="promo.image" alt="" class="promo-image" />
                        </div>
                        <div class="promo-content">
                            <h2 class="promo-title">{{ promo.title }}</h2>
                            <p class="promo-description">{{ promo.description }}</p>
                            <v-btn color="primary" class="promo-button"
                                @click="navigateTo(promo.category)">CONFIRA</v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid class="fixed-banner">
            <v-row>
                <v-col>
                    <img :src="this.storeData.bannerFixed" alt="">
                </v-col>
            </v-row>
        </v-container>

        <Footer :isMobile="$store.getters.isMobile" />

        <Loading v-if="loading" />
    </v-app>
</template>

<script>
import Notice from '@/components/Notice.vue'
import Navbar from '@/components/Navbar.vue'
import FourProducts from '@/components/FourProducts.vue'
import CostumersRate from '@/components/CostumersRate.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})
export default {
    name: 'StorePage',
    metaInfo() {
        return {
            title: `${this.storeData.name}`
        }
    },
    components: {
        Notice,
        Navbar,
        FourProducts,
        CostumersRate,
        Footer,
        Loading
    },
    data() {
        return {
            loading: true,
            message: "Cadastre-se e ganhe 20% de desconto no seu primeiro pedido. Inscreva-se agora",
            title: 'Site Loja',
            productsInCart: 0,
            storeData: {},
            categories: [],
            newProducts: [],
            carouselImages: [], // Novo estado para armazenar as imagens do carrossel
            promos: [],
            rates: [
                {
                    id: 1,
                    rate: 5,
                    name: 'Cliente 1',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 2,
                    rate: 4,
                    name: 'Cliente 2',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 3,
                    rate: 3,
                    name: 'Cliente 3',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 4,
                    rate: 2,
                    name: 'Cliente 4',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 5,
                    rate: 1,
                    name: 'Cliente 5',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                }
            ]
        }
    },
    async mounted() {
        this.verifyQntCart()

    },
    async created() {
        this.loading = true;
        this.verifyQntCart()
        this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
        this.carouselImages = this.getCarouselImages(); // Popula as imagens do carrossel
        this.loading = false;

        this.promos = [
                {
                    title: 'LANÇAMENTOS',
                    description: 'Confira nossos lançamentos!',
                    image: this.storeData.banner1,
                    category: 'lancamentos-junho'
                },
                {
                    title: 'MAIS VENDIDOS',
                    description: 'Confira nossos produtos mais vendidos!',
                    image: this.storeData.banner2,
                    category: 'mais-vendidos'
                },
                {
                    title: 'CATÁLOGO COMPLETO',
                    description: 'Confira nosso catálogo completo!',
                    image: this.storeData.banner3,
                    category: 'todos-oculos-de-sol'
                },
                
            ]
    },
    methods: {
        verifyQntCart() {
            this.productsInCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0
        },
        getCarouselImages() {
            // Aqui você pode adicionar mais URLs de imagens ao carrossel
            let images = []

            if (this.storeData.topCase) {
                images.push(this.storeData.topCase)
            }

            if (this.storeData.topCase2) {
                images.push(this.storeData.topCase2)
            }

            if (this.storeData.topCase3) {
                images.push(this.storeData.topCase3)
            }

            return images
        },
        goTo(path){
            this.$router.push(this.$route.params.storeSlug == undefined ? `${path}` : `/${this.$route.params.storeSlug}${path}`)
        },
        navigateTo(category) {
            this.$router.push(this.$route.params.storeSlug === undefined ? `category/${category}` : `/${this.$route.params.storeSlug}/category/${category}`)
        }
    }
}
</script>


<style scoped>
* {
    font-family: "Exo", sans-serif;
}

::placeholder {
    color: #00000066;
    opacity: 1;
    /* Firefox */
}

.input-default {
    width: 100%;
    padding: 10px;
    padding-left: 15px;
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    border-radius: 50px;
}

.top-banner {
    padding: 0;
    cursor: pointer;
}

.carousel-img {
    width: 100%;
    object-fit: cover;
}

.promo-section {
    margin-top: 20px;
    margin-bottom: 20px;
}

.promo-card {
    position: relative;
    width: 100%;
    height: 300px;
    /* Define um tamanho fixo para os cards */
    text-align: center;
    color: white;
    overflow: hidden;
}

.promo-image-wrapper {
    width: 100%;
    height: 100%;
}

.promo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Faz com que a imagem se ajuste ao tamanho do card */
    filter: brightness(50%);
}

.promo-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.promo-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.promo-description {
    font-size: 16px;
    margin: 10px 0;
}

.promo-button {
    background-color: #00bcd4;
    color: white;
}

.top .top-separator {
    margin-bottom: 25vh;
}

.top .top-separator img.banner1 {
    position: absolute;
    margin-top: -20vh;
    width: 100%;
}

.top .top-separator img.banner2 {
    position: absolute;
    margin-top: -50vh;
    width: 100%;
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .top .top-separator img.banner1 {
        position: absolute;
        margin-top: -15vh;
        width: 100%;
    }

    .top .top-separator img.banner2 {
        position: absolute;
        margin-top: -43vh;
        width: 100%;
    }
}

/* TOP SEPARATOR MOBILE */
.top .top-separator-mobile {
    margin-bottom: 10vh;
}

.top .top-separator-mobile img.banner1 {
    position: absolute;
    top: 9rem;
    width: 100%;
}

.top .top-separator-mobile img.banner2 {
    position: absolute;
    top: 21rem;
    width: 100%;
}


.fixed-banner img{
   width: 100%;
   display: block;
    margin: 0 auto;
}
</style>
