<template>
    <v-app>
        <!-- <Notice :isMobile="$store.getters.isMobile" :message="message"/> -->

        <Navbar :isMobile="$store.getters.isMobile" :categories="categories" :productsInCart="productsInCart"/>
        <v-container fluid class="body">
            <v-row>
                <v-col>
                    <h3 style="text-align: center;"> Resultado da pesquisa: {{ this.$route.params.search }} </h3>

                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <FourProducts :isCategoryPage="false"  :isMobile="$store.getters.isMobile" :products="newProducts" />
                </v-col>
            </v-row>
        </v-container>
    
        <Footer :isMobile="$store.getters.isMobile" />

        <Loading v-if="loading"/>
    </v-app>
</template>

<script>
import Notice from '@/components/Notice.vue'
import Navbar from '@/components/Navbar.vue'
import FourProducts from '@/components/FourProducts.vue'
import CostumersRate from '@/components/CostumersRate.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})
export default {
    name: 'CategoryPage',
    metaInfo() {
        return {
            title: `${this.storeData.name} - Categoria - ${this.category.name}`
        }
    },
    components: {
        Notice,
        Navbar,
        FourProducts,
        CostumersRate,
        Footer,
        Loading
    },
    data(){
        return {
            loading: true,
            message: "Cadastre-se e ganhe 20% de desconto no seu primeiro pedido. Inscreva-se agora",
            title: 'Site Loja',
            productsInCart: 0,
            category: {},
            storeData: {},
            categories: [],
            products: [],
            newProducts: [],
        }
    },
    async mounted(){
        this.verifyQntCart()
        
    },
    async created(){
        await this.init()
    },
    watch: {
        async '$route' (to, from) {
            await this.init()
        }
    },
    methods: {
        async init(){
            this.loading = true;
            this.verifyQntCart()
            this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
            await this.getCategories();
            await this.getProducts();
            this.loading = false;
        },
        verifyQntCart(){
            this.productsInCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0
        },
        async getCategories(){
            try {
                let response = await api.get(`categories`)
                this.categories = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async getProducts(){
            try {
                let response = await api.post(`store/${this.storeData.id}/products`, {search: this.$route.params.search, published: 1})
                this.products = response.data
                this.newProducts = response.data 
            } catch (error) {
                console.log(error)
            }
        },
        goTo(path){
            this.$router.push(this.$route.params.storeSlug == undefined ? `${path}` : `/${this.$route.params.storeSlug}${path}`)
        }
    }
}
</script>

<style scoped>
    *{
        font-family: "Exo", sans-serif;
    }
    ::placeholder {
        color: #00000066;
        opacity: 1; /* Firefox */
    }

    .input-default{
        width: 100%;
        padding: 10px;
        padding-left: 15px;
        border: 1px solid #F0F0F0;
        background-color:#F0F0F0;
        border-radius: 50px;
    }

    .top-banner{
        padding: 0;
    }
    .top-banner .img{
        width: 100%;
    }
    .top .top-separator{
        margin-bottom: 25vh;
    }

    .top .top-separator img.banner1{
        position: absolute;
        margin-top: -20vh;
        width: 100%;

    }
    .top .top-separator img.banner2{
        position: absolute;
        margin-top: -50vh;
        width: 100%;
    }

    @media (min-width: 1024px) and (max-width: 1440px) {
        .top .top-separator img.banner1{
            position: absolute;
            margin-top: -15vh;
            width: 100%;
        }
        .top .top-separator img.banner2{
            position: absolute;
            margin-top: -43vh;
            width: 100%;
        }
    }


    /* TOP SEPARATOR MOBILE */
    .top .top-separator-mobile{
        margin-bottom: 10vh;
    }
    .top .top-separator-mobile img.banner1{
        position: absolute;
        top: 9rem;
        width: 100%;

    }
    .top .top-separator-mobile img.banner2{
        position: absolute;
        top: 21rem;
        width: 100%;
    }


    ul{
        list-style: none;
        padding: 0;
        margin-top: 10px;
    }

    ul li{
        cursor: pointer;
        padding: 10px;
        border-bottom: 1px solid #00000033;
    }
</style>